import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from "react-i18next";


const Container = styled('div')({
  width: '50%',
  margin: 'auto',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '1rem',
  marginRight: '1rem',
}));

const DropzoneContainer = styled('div')({
  border: '2px dashed #eeeeee',
  borderRadius: '4px',
  padding: '2rem',
  textAlign: 'center',
  cursor: 'pointer',
});

const PreviewImage = styled('img')({
  display: 'block',
  maxWidth: '100%',
  margin: '1rem auto',
});

export const ImageBackgroundRemover = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const { t } = useTranslation();

  
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setResult(null);
    setPreviewSrc(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const removeBackground = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image_file', file);
    formData.append('size', 'auto');

    const apiKey = '11z9mWkhr9THCsHdHd6EQk5s'; // Remplacez ceci par votre clé API remove.bg

    try {
      const response = await axios.post('https://api.remove.bg/v1.0/removebg', formData, {
        headers: {
          'X-Api-Key': apiKey,
        },
        responseType: 'blob',
      });

      const imageUrl = URL.createObjectURL(response.data);
      setResult(imageUrl);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la requête API', error);
    }
    setLoading(false);
  };

  const resetComponent = () => {
    setFile(null);
    setResult(null);
    setPreviewSrc(null);
  };

  return (
    <Container>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
        <p>{t("Relâchez pour ajouter cette image.3")}</p>
        ) : (
          <p>{t("Glissez et déposez une image ici ou cliquez pour sélectionner une image.4")}</p>
        )}
      </DropzoneContainer>
      {previewSrc && <PreviewImage src={previewSrc} alt="Aperçu" />}
      <StyledButton
        variant="contained"
        color="primary"
        onClick={removeBackground}
        disabled={!file || loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Supprimer l\'arrière-plan'}
      </StyledButton>
      <StyledButton variant="outlined" color="secondary" onClick={resetComponent}>
        Réinitialiser
      </StyledButton>
      {result && (
        <div>
          <h3>Résultat d'image en png :</h3>
          <img src={result} alt="Résultat" style={{ maxWidth: '100%' }} />
        </div>
      )}
    </Container>
  );
};
