import React, { useState, useCallback, useEffect } from "react";
import { Button, TextField, Select, MenuItem, Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./ImageResizer.css";
import { useTranslation } from "react-i18next";


const ImageResizer = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  const [imageFormat, setImageFormat] = useState("image/jpeg");
  const { t } = useTranslation();
  
  useEffect(() => {
    document.title = "ImageResizer";
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "image/*" });

  const resizeImage = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        setResizedImage(canvas.toDataURL(imageFormat));
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = resizedImage;
    link.download = `resized-image.${imageFormat.split('/')[1]}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (

   
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

<h4> {t("L'outil de redimensionnement d'image facilite la modification de la taille des images selon les besoins des utilisateurs. Il propose une interface conviviale pour charger, ajuster les dimensions et télécharger les images redimensionnées. C'est une solution pratique pour adapter rapidement les images en ligne ou pour l'impression.16")} </h4>


      <Box
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "drag-active" : ""}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="subtitle1">{t("Relâchez pour ajouter cette image.3")}</Typography>
        ) : (
          <Typography variant="subtitle1">{t("Glissez et déposez une image ici ou cliquez pour sélectionner une image.4")}</Typography>
        )}
      </Box>
      {selectedImage && (
        <Box>
          <TextField
            label="Width"
            type="number"
            value={width}
            onChange={(e) => setWidth(parseInt(e.target.value))}
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Height"
            type="number"
            value={height}
            onChange={(e) => setHeight(parseInt(e.target.value))}
            variant="outlined"
            margin="normal"
          />

<br />

          <Select value={imageFormat} onChange={(e) => setImageFormat(e.target.value)} variant="outlined" margin="normal">
            <MenuItem value="image/jpeg">JPEG</MenuItem>
            <MenuItem value="image/png">PNG</MenuItem>
            <MenuItem value="image/webp">WebP</MenuItem>
          </Select>

          <br />
          <br />
          <Button onClick={resizeImage} variant="contained" color="primary" sx={{ marginLeft: 2 }}>
            Resize Image
          </Button>
        </Box>
      )}
      {resizedImage && (
        <Box sx={{ marginTop: 2 }}>
          <img src={resizedImage} alt="Resized" style={{ maxWidth: "100%" }} />


<br />
<br />


          <Button variant="contained" color="primary" onClick={handleDownloadClick} sx={{ marginTop: 1 }}>
            Télécharger l'image redimensionnée
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ImageResizer;
