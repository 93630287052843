import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import ImageUploader from "./components/ImageUploader";
import TranslatedText from "./components/TranslatedText";
import LanguageToolCheck from "./components/LanguageToolCheck";
import HomePage from "./components/HomePage";
import RGBtoHEXConverter from "./components/RGBtoHEXConverter";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import { ReactComponent as WorldIcon } from "../src/components/icons/world-grid.svg";
import Container from "@mui/material/Container";
import DropdownMenu from "./components/DropdownMenu";
import { useTranslation } from "react-i18next";
import ImageEditor from "./components/ImageEditor";
import { ImageBackgroundRemover } from './components/ImageBackgroundRemover';
import ImageResizer  from './components/ImageResizer';

function App() {
  const [extractedText, setExtractedText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [translatedText, setTranslatedText] = useState("");
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  function Navbar3(props) {
    return (
      <nav className="navbar">
        <ul className="navbar-nav"> {props.children}</ul>
      </nav>
    );
  }
  function NavItem(props) {
    const [open, setOpen] = useState(false);
    return (
      <li className="nav-item">
        <div className="icon-button" onClick={() => setOpen(!open)}>
          {props.icon}
        </div>
        {open && props.children}
      </li>
    );
  }

  const handleResetClick = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setExtractedText("");
    setTranslatedText("");
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/RGBtoHEXConverter">RGBtoHEXConverte</Link>
          </li>
         <li>
                <Link to="/ImageBackgroundRemover">ImageBackgroundRemover</Link>
              </li>
             <li>
                <Link to="/ImageResizer">ImageResizer</Link>
              </li>   
 {/* <li>
  <Link to="/imageeditor">Éditeur d'image</Link>
</li>  */}

          <li>
            <Link to="/imagetotext"> IMAGE TO TEXT </Link>
          </li>
        </ul>

        <div className="navi18 " style={{ marginTop: "2px" }}>
          <NavItem icon={<WorldIcon />}>
            <DropdownMenu />
          </NavItem>
        </div>
      </nav>
  <button onClick={toggleDarkMode}>{t("Basculer vers le mode sombre.10")}</button>
      <div className="App">
        <header className="nav">
          <h1>Toolgeniuses</h1>

        

         
        </header>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/RGBtoHEXConverter"
            element={
              <Container maxWidth="xs">
                <RGBtoHEXConverter />
              </Container>
            }
          />

          <Route path="/ImageBackgroundRemover" element={<ImageBackgroundRemover />} />
          <Route path="/ImageResizer" element={
          
          
          
          
          <ImageResizer/>} />
          <Route path="/imageeditor" element={<ImageEditor />} />

          <Route
            path="/imagetotext"
            element={
              <>
                <h4>
                  {t(
                    "Notre programme d'extraction de texte et de traduction multi-langues est conçu pour simplifier la traduction de texte à partir d'images. Avec cette technologie avancée, vous pouvez extraire facilement le texte d'une image et obtenir des traductions précises dans plusieurs langues. Que vous soyez un professionnel de la traduction, un étudiant ou simplement quelqu'un qui souhaite comprendre rapidement le contenu d'une image, notre programme est là pour vous aider..2"
                  )}{" "}
                </h4>
                <ImageUploader
                  setExtractedText={setExtractedText}
                  handleResetClick={handleResetClick}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  previewUrl={previewUrl}
                  setPreviewUrl={setPreviewUrl}
                  translatedText={translatedText}
                  setTranslatedText={setTranslatedText}
                />
                <LanguageToolCheck sourceText={extractedText} />

                <TranslatedText
                  sourceText={extractedText}
                  translatedText={translatedText}
                  setTranslatedText={setTranslatedText}
                />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;