import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageUploader.css";
import Tesseract from "tesseract.js";
import { useTranslation } from "react-i18next";

const ImageUploader = ({ setExtractedText, handleResetClick, selectedFile, setSelectedFile, previewUrl, setPreviewUrl}) => {
  const { t } = useTranslation();
  // const [previewUrl, setPreviewUrl] = useState(null);
  const [extracting, setExtracting] = useState(false);
  
  useEffect(() => {
   
    document.title="ImageTOText"
     
    }, [])
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive: dropzoneDragActive } =
    useDropzone({ onDrop, accept: "image/*" });

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };

  const extractText = async (image) => {
    setExtracting(true);
    try {
      const result = await Tesseract.recognize(image, "eng", {
        logger: (m) => console.log(m),
      });
      setExtractedText(result.data.text);
    } catch (err) {
      console.error("Failed to extract text from image:", err.message);
    } finally {
      setExtracting(false);
    }
  };
  

  const handleUploadClick = () => {
    if (selectedFile) {
      extractText(selectedFile);
    }
  };

  return (
    <div className="image-uploader">
      <h1>{t("Uploader une image.7")} : </h1> 
      <div
        {...getRootProps()}
        className={`dropzone ${dropzoneDragActive ? "drag-active" : ""}`}
      >
        <input {...getInputProps()} type="file" onChange={handleFileInputChange} />
        {dropzoneDragActive ? (
          <p>{t("Relâchez pour ajouter cette image.3")}</p>
        ) : (
          <p>{t("Glissez et déposez une image ici ou cliquez pour sélectionner une image.4")}</p>
        )}
      </div>
      {selectedFile && (
        <div className="selected-file">
          <img src={previewUrl} alt="Aperçu de sélectionnée" className="image-preview" />
          {extracting ? (
            <p>Extraction en cours...</p>
          ) : (
            <>
              <button onClick={()=> handleUploadClick()}>{t("Extraire le texte.5")}</button>
              <button onClick={ ()=>  handleResetClick()}>{t("Réinitialiser.6")}</button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
