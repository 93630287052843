import React from 'react';

function Page3() {
  return (
    <div>
      <h1>Page 3</h1>
    </div>
  );
}

export default Page3;
