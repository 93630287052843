import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LanguageToolCheck = ({ sourceText }) => {
  const [corrections, setCorrections] = useState([]);

  useEffect(() => {
    const checkText = async () => {
      if (sourceText) {
        const options = {
          method: 'POST',
          url: 'https://dnaber-languagetool.p.rapidapi.com/v2/check',
          headers: {
            'X-RapidAPI-Key': '173d29eb78msh656d1d9affac816p1519fajsn4e72c1766fee',
            'X-RapidAPI-Host': 'dnaber-languagetool.p.rapidapi.com',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: {
            text: sourceText,
            language: 'en',
          },
        };

        try {
          const response = await axios.request(options);
          setCorrections(response.data.matches);
        } catch (error) {
          console.error('Error while checking text:', error);
        }
      }
    };

    checkText();
  }, [sourceText]);

  return 
};

export default LanguageToolCheck;
