import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { fabric } from "fabric";
import "./ImageEditor.css";

const ImageEditor = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [brushSize, setBrushSize] = useState(5);
  const [selectedTool, setSelectedTool] = useState("pencil");
  const canvasRef = useRef(null);
  const canvasInstance = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        setUploadedImage(binaryStr);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = new fabric.Canvas(canvasRef.current);
    canvasInstance.current = canvas;

    if (uploadedImage) {
      fabric.Image.fromURL(uploadedImage, (img) => {
        img.scaleToWidth(canvas.getWidth());
        img.set({ top: 0, left: 0 });
        canvas.add(img);
        canvas.moveTo(img, 0);
      });
    }

    canvas.isDrawingMode = selectedTool === "pencil";
    canvas.freeDrawingBrush.width = brushSize;
    canvas.freeDrawingBrush.color = "black";

    canvas.on("mouse:down", (options) => {
      if (selectedTool === "eraser" && options.target) {
        canvas.remove(options.target);
      }
    });

    return () => {
      canvas.off("mouse:down");
    };
  }, [canvasRef, uploadedImage, brushSize, selectedTool]);

  const handleBrushSizeChange = (event) => {
    setBrushSize(event.target.value);
  };

  const undo = () => {
    const canvas = canvasInstance.current;
    if (!canvas) return;

    const history = canvas.getObjects();
    if (history.length > 1) {
      canvas.remove(history[history.length - 1]);
      canvas.renderAll();
    }
  };

  const resetCanvas = () => {
    const canvas = canvasInstance.current;
    if (!canvas) return;

    canvas.getObjects().forEach((obj) => {
      if (obj !== canvas.item(0)) {
        canvas.remove(obj);
      }
    });
    canvas.renderAll();
  };

  return (
    <div className="image-editor">
      <h2>Éditeur d'image</h2>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Faites glisser et déposez des images ici, ou cliquez pour sélectionner des fichiers</p>
      </div>
      <div>
        <label htmlFor="brush-size">Taille du pinceau: </label>
        <select id="brush-size" value={brushSize} onChange={handleBrushSizeChange}>
          <option value="1">1px</option>
          <option value="2">2px</option>
          <option value="3">3px</option>
          <option value="4">4px</option>
          <option value="5">5px</option>
          <option value="10">10px</option>
          <option value="15">15px</option>
          <option value="20">20px</option>
          <option value="25">25px</option>
          <option value="30">30px</option>
        </select>
      </div>
      <div>
        <button onClick={() => setSelectedTool("pencil")}>Pinceau</button>
        <button onClick={() => setSelectedTool("eraser")}>Gomme</button>
        <button onClick={undo}>Annuler</button>
        <button onClick={resetCanvas}>Réinitialiser</button>
      </div>
      <div className="canvas-container">
        <canvas ref={canvasRef} id="canvas" width="800" height="400" />
      </div>
    </div>
  );
};

export default ImageEditor;




//  