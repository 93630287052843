import React, { useState } from "react";
import axios from "axios";
import "./TranslatedText.css";
import detectBrowserLanguage from "detect-browser-language";
import { useTranslation } from "react-i18next";



const API_KEY = "AIzaSyC_IDV1zpkDberKQtwBk7bY2Y_udW9Hzl0";

const TranslatedText = ({ sourceText, translatedText, setTranslatedText }) => {
  let language = detectBrowserLanguage();

  const [selectedLanguage, setSelectedLanguage] = useState(
    language.slice(0, 2)
  );
  const languageOptions = {
    en: "Anglais",
    es: "Espagnol",
    pt: "Portugais",
    it: "Italien",
    de: "Allemand",
    fr: "Francais",
  };

  const handleLanguageChange = (event) => {
    console.log(event.target.value);
    setSelectedLanguage(event.target.value);
  };
  const { t } = useTranslation();
  const detectAndTranslateText = async () => {
    if (sourceText) {
      try {
        const detectResponse = await axios.post(
          `https://translation.googleapis.com/language/translate/v2/detect?key=${API_KEY}`,
          new URLSearchParams({ q: sourceText })
        );
        const detectedLanguage =
          detectResponse.data.data.detections[0][0].language;

        const translateResponse = await axios.post(
          `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`,
          new URLSearchParams({
            q: sourceText,
            target: selectedLanguage,
            source: detectedLanguage,
          })
        );

        setTranslatedText(
          translateResponse.data.data.translations[0].translatedText
        );
      } catch (error) {
        console.error(
          "Error while detecting and translating text:",
          error.message
        );
      }
    }
  };

  const handleTranslateClick = () => {
    console.log("Translating...");
    detectAndTranslateText();
  };

  return (
    <div>

<button className="translated-textt" onClick={handleTranslateClick}>
       {t("Traduire.8")}
      </button>

      <br/>
      
      
      <select value={selectedLanguage} onChange={handleLanguageChange}>
      <option value={"en"}> Anglais</option>

        <option value={"es"}>Espagnol</option>

        <option value={"pt"}>Portugais</option>

        <option value={"it"}>Italie</option>

        <option value={"de"}>Allemand</option>

        <option value={"he"}>Hebreu</option>
        <option value={"fr"}>Francais</option>

      </select>
      
      {sourceText && <h3>Texte original:</h3>}
      <p className="original-text">{sourceText}</p>
      {translatedText && <h3>Texte traduit:</h3>}
      <p className="translated-text">{translatedText}</p>
    </div>
  );
};

export default TranslatedText;
