import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Paper,
  Typography,
  InputLabel,
  FormControl,
  IconButton,
  Snackbar,
  Container,
  Grid,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { GlobalStyles } from "@mui/system";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";




const RGBtoHEXConverter = () => {
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });
  const [hex, setHex] = useState("#000000");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const { r, g, b } = rgb;
    setHex("#" + rgbToHex(r) + rgbToHex(g) + rgbToHex(b));
  }, [rgb]);

  useEffect(() => {
    document.title = "RGBtoHEXConverter";
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRgb({
      ...rgb,
      [name]: Math.max(0, Math.min(255, parseInt(value))),
    });
  };

  const handleColorPickerChange = (color) => {
    setRgb(color.rgb);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(hex).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const rgbToHex = (rgb) => {
    const hex = rgb.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return (
    <>


    <div className="rgbhex"> {t("Notre programme de convertisseur RGB vers HEX est un outil pratique et efficace pour convertir les codes de couleur RGB en codes HEX. Que vous soyez un designer, un développeur web ou simplement quelqu'un qui travaille avec des couleurs, notre programme vous permettra de convertir rapidement et précisément vos valeurs RGB en codes HEX, simplifiant ainsi votre flux de travail..15")}</div>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: hex,
            transition: "background-color 0.3s ease",
          },
        }}
      />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          container
          component={Paper}
          p={4}
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={3}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderRadius: "15px",
          }}
        >
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Convertisseur RGB vers HEX
            </Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel htmlFor="color-picker"></InputLabel>
              <Box mt={2}>
                <SketchPicker
                  id="color-picker"
                  color={rgb}
                  disableAlpha
                  onChangeComplete={handleColorPickerChange}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label="R"
                  name="r"
                  type='number'
                  value={rgb.r}
                  onChange={handleChange}
                  inputProps={{ min: 0, max: 255 }}
                  fullWidth
                  />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                  variant="outlined"
                  label="G"
                  name="g"
                  type="number"
                  value={rgb.g}
                  onChange={handleChange}
                  inputProps={{ min: 0, max: 255 }}
                  fullWidth
                  />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                  variant="outlined"
                  label="B"
                  name="b"
                  type="number"
                  value={rgb.b}
                  onChange={handleChange}
                  inputProps={{ min: 0, max: 255 }}
                  fullWidth
                  />
                  </Grid>
                  </Grid>
                  </Grid>
                  <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                  <Typography variant="h6">HEX: {hex}</Typography>
                  </Grid>
                  <Grid item>
                  <IconButton onClick={handleCopyClick}>
                  <FileCopyIcon />
                  </IconButton>
                  </Grid>
                  </Grid>
                  </Grid>
                  <Snackbar
                           open={snackbarOpen}
                           autoHideDuration={3000}
                           onClose={handleSnackbarClose}
                           message="Couleur HEX copiée !"
                         />
                  </Grid>
                  </Container>
                  </>
                  );
                  };
                  
                  export default RGBtoHEXConverter;