import React from 'react';
import { useTranslation } from "react-i18next";
import './HomePage.css';
import imgtxt from '../components/icons/txtimg.png';
import rgb from '../components/icons/rgb.png';
import pute from '../components/icons/pute.png';
import imageRight from './icons/imageRight.png'; // Remplacez ceci par le chemin de votre image

function HomePage() {
  const { t } = useTranslation();
  return (
    <div className="home-page">
      <h1>{t("Bienvenue sur la page Toolgeniuses.1")}</h1>
      <h3 className='textacceuil'>
        {t("Bienvenue sur ToolsGeniuses, votre hub d'outils Web incontournable! Maîtrisez vos projets avec notre extracteur de texte, notre convertisseur RVB vers HEX, et plus encore. Soutenez-nous, libérez un potentiel illimité et laissez ToolsGeniuses propulser votre voyage vers le succès. Embrassez le génie en vous !.11")}
      </h3>
      <div className="tools-preview-container">
        <div className="tool-preview">
          <img src={imgtxt} alt="Text Extractor" className="tool-image" />
          <h4>Tool 1</h4>
          <p>Description of Tool 1</p>
        </div>
        <div className="tool-preview">
          <img src={rgb} alt="RGB to HEX Converter" className="tool-image" />
          <h4>Tool 2</h4>
          <p>Description of Tool 2</p>
        </div>
        <div className="tool-preview">
          <img src={pute} alt="Another Tool" className="tool-image" />
          <h4>Tool 3</h4>
          <p>Description of Tool 3</p>
        </div>
     
      </div>
      <div className="text-image-section">
        <h3 className="left-text">
        <p className='textacceuil'
        
        >{t("Êtes-vous fatigué de devoir retaper du texte à partir d'une image ? Grâce à notre technologie de reconnaissance optique de caractères (OCR), notre outil en ligne gratuit convertit les images en texte avec une précision de 100% en quelques secondes seulement. Plus besoin de perdre votre temps à saisir manuellement du texte à partir d'une image, utilisez notre convertisseur d'images en texte dès maintenant.17")}</p>
        </h3>
        <div className="right-image">
          <img src={imageRight} alt="Image à droite" />
        </div>
      </div>
    </div>
  );
}

export default HomePage;